.page-loading-wrapper {
    content: "";
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    transition: all 0.5s ease-in;
}

.active.page-loading-wrapper::before {
    visibility: visible;
    opacity: 1;
    transition: all 0.5s ease-in;
}

.passive.page-loading-wrapper {
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s ease-in;
}

// .image-zoom-hover {
//     width: 180px;
//     height: 180px;
//     overflow: hidden;
// }
.image-zoom-hover img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.image-zoom-hover:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.switch-lg>input {
    width: 64px !important;
    height: 32px !important;
}

.dual-list-dark-mode button {
    background-color: var(--falcon-input-bg) !important;
    color: var(--falcon-input-color) !important;
    border: 1px solid var(--falcon-input-border-color) !important;
    transition: all .3s;
}

.dual-list-dark-mode button:hover {
    background-color: var(--falcon-input-bg) !important;
    color: var(--falcon-input-color) !important;
    box-shadow: 0px 0px 5px #334353 !important;
    border: 1px solid var(--falcon-input-border-color) !important;
}

.dual-list-dark-mode button:focus {
    outline: none !important;
}

.dual-list-dark-mode button:active {
    box-shadow: none !important;
}

.dual-list-dark-mode select {
    background-color: var(--falcon-input-bg);
    border: 1px solid var(--falcon-input-border-color) !important;
    box-shadow: var(--falcon-box-shadow-inset);
    color: var(--falcon-input-color);
}

.dual-list-dark-mode select:focus-visible {
    outline: none !important;
}

.react-tel-input .form-control {
    width: 100% !important;
    height: unset !important;
}

.dark-phone-input {
    background-color: var(--falcon-input-bg) !important;
    border: 1px solid #334353 !important;
}

.dark-phone-input-flag {
    background: var(--falcon-input-bg) !important;
    border: 1px solid var(--falcon-input-border-color) !important;
}

.dark-phone-input-flag:hover {
    background: var(--falcon-input-bg) !important;
    border: 1px solid var(--falcon-input-border-color) !important;
}

.dark-phone-input-flag .selected-flag {
    background: var(--falcon-input-bg) !important;
}

.dark-phone-input-flag .selected-flag:hover {
    background: var(--falcon-input-bg) !important;
}

.dark-phone-input-flag .selected-flag:focus {
    background: var(--falcon-input-bg) !important;
}

.dark-phone-input-flag li {
    background: var(--falcon-input-bg) !important;
}

.dark-phone-input-flag span {
    color: var(--falcon-input-color);
}

.upload-button {
    display: block;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid var(--falcon-input-border-color);
    background: var(--falcon-input-bg);
    text-align: center;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

}


.order-product-filter-list {
    cursor: pointer;
    transition: all .4s;
    border: 1px solid var(--falcon-input-border-color) !important;
}

.order-product-filter-list:hover {
    box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.1);
}


.blue-text-btn {
    color: #0096FF;
    cursor: pointer;
}

.drag-handler {
    position: absolute;
    right: 5px;
    top: 5px;
    color: #2c74e5;
    cursor: grab;
}

.drag-handler:active {
    cursor: grabbing;
}

.circle-number {
    border-radius: 50%;
    color: #000000;
    background-color: #dde0e4;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 27px;
    left: 27px;
}

table .form-control {
    font-size: 0.8rem;
    line-height: 2;
}

.vertical-line {
    border-left: 1px solid #000;
    /* Çizginin rengi */
    height: 50px;
    /* Çizginin yüksekliği */
    margin: 0;
    /* Gereksiz boşlukları kaldırmak için */
}

.react-select__menu {
    z-index: 10 !important;
    /* İstediğiniz z-index değerini ayarlayın */
}

.img-50-contain {
    width: 50px;
    height: 50px;
    object-fit: contain;
}


.category-list-description {
    width: 300px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    p {
        margin: 0px;
        display: inline-block;
        width: 300px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.ml-auto {
    margin-left: auto !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.font-weight-bold {
    font-weight: 700;
}

.react-daterange-picker__wrapper {
    border-radius: 4px !important;
    border: thin solid hsl(0, 0%, 80%)!important;
}
.react-daterange-picker__button {
    svg {

        stroke: hsl(0, 0, 40%) !important;
    }
}

.page-item .page-link {
    font-size: 12px;
}


.color-red {
    color: #e63757;
}

.max-1-line {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}